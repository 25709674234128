import request from '@/utils/request'
import qs from 'qs';

// 获取专题列表
export function getSpecial() {
  return request({
    url: '/product/spec',
    method: 'post'
  })
}

// 获取轮播图
export function getBanner(data) {
  return request({
    url: '/index/banner',
    method: 'post',
    data:qs.stringify(data)
  })
}

// 获取二级专题下的套餐
export function getCombo(data) {
  return request({
    url: '/product/getComboByClass',
    method: 'post',
    data:qs.stringify(data)
  })
}

// 获取推荐题库
export function getTestBank(data) {
  return request({
    url: '/index/testbank',
    method: 'post',
    data:qs.stringify(data)
  })
}

