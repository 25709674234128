<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>
    
    <div class="container">
      <!-- 专题、banner -->
      <el-row :gutter="20" class="mt-1">
        <el-col :span="8">
          <!-- 专题 -->
          <div class="special">
            <div class="special-item" v-for="item in specialList" :key="item.id">
              <div class="special-item-title">
                <div class="title-container">
                  <svg-icon :iconClass="item.icon"></svg-icon>
                  <div>{{ item.title }}</div>
                </div>
              </div>
              <div class="special-item-children">
                <span class="children-item" @click="this.$router.push('/special/' + child.id)"
                  v-for="child in item.children" :key="child.id">{{ child.title }}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <!-- 轮播图 -->
          <div class="swiper-wrap">
            <swiper :slides-per-view="1" :space-between="0" navigation :autoplay="{ delay: 3000, disableOnInteraction: false }" loop :pagination="{ clickable: true }">
              <swiper-slide @click="adInfo(item)" v-for="(item, index) in bannerList" :key="index">
                <div class="swiper-content-img"><img :src="item.thumb" alt=""></div>
              </swiper-slide>
            </swiper>
          </div>
        </el-col>
      </el-row>

      <!-- 专题及套餐 -->
      <el-row :gutter="20" class="mt-3">
        <el-col :span="index == specialList.length - 1 ? 24 : 12" v-for="(item, index) in specialList" :key="item.id">
          <div :class="index == specialList.length - 1 ? 'combo last' : 'combo'">
            <div class="combo-header">
              <div class="combo-header-title">{{item.title}}</div>
              <div class="combo-header-sub">
                <span :class="item.currentSpecialChild.id == child.id ? 'sub-item active' : 'sub-item'" @click="getComboList(index, childIndex)" 
                  v-for="(child, childIndex) in item.children" :key="child.id">{{ child.title }}</span>
                <span class="sub-item" @click="goCourse(item)">更多<i class="el-icon-caret-right"></i></span>
              </div>
            </div>
            <div class="combo-list">
              <div class="combo-list-item" @click="goCourseDetail(combo)" v-for="combo in item.comboList" :key="combo.id">
                <div class="item-img"><img :src="combo.thumb" alt=""></div>
                <div class="item-title" :title="combo.title">{{ combo.title }}</div>
                <div class="item-bottom">
                  <div class="bottom-price">￥{{ combo.price }}<span>起</span></div>
                  <div class="bottom-num">{{ combo.num }}人已购买</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 推荐题库 -->
      <div class="exam">
        <div class="exam-header">
          <div class="header-title">推荐题库</div>
          <div class="header-more" @click="this.$router.push('/examlist')">更多<i class="el-icon-caret-right"></i></div>
        </div>
        <div class="exam-list">
          <div class="exam-list-item" @click="goSecretDetail(item)" v-for="item in examList" :key="item.id">
            <div class="item-img"><img :src="item.thumb" alt=""></div>
            <div class="item-title" :title="item.name">{{ item.name }}</div>
            <div class="item-bottom">
              <div class="bottom-price">￥{{ item.sale_price }}</div>
              <div class="bottom-num">{{ item.number }}人已购买</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay} from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay])

import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import SvgIcon from '@/components/svgicon'

import { getSpecial, getBanner, getCombo, getTestBank } from "@/api/index5"
import { setSessionStorage } from '@/utils/auth'

export default {
  components: {
    Swiper,
    SwiperSlide,
    NavBar,
    ToolBox,
    WebFooter,
    SvgIcon
  },
  data() {
    return {
      // 专题
      specialList: [],
      // 轮播图
      bannerList: [],
      // 题库
      examList: [],

    }
  },
  created() {
    // 获取专题
    getSpecial().then(res => {
      if (res.code == 1) {
        this.specialList = res.data
        this.specialList.forEach((item, index) => {
          // 给每个一级专题配上icon
          switch(item.id) {
            case 5:
              item.icon = 'F-jingji'
              break
            case 7:
              item.icon = 'jianzhuye'
              break
            case 41:
              item.icon = 'yixuefenhui'
              break
            case 48:
              item.icon = 'education'
              break
            case 1:
              item.icon = 'zhiye'
              break
          }
          // 默认请求第一个二级专题的套餐列表
          this.getComboList(index, 0)
        })
      }
    })
    // 获取轮播图
    getBanner().then(res => {
      if (res.code == 1) {
        this.bannerList = res.data;
      }
    })
    // 获取推荐题库
    getTestBank().then(res => {
      if (res.code == 1) {
        let typeNum = []
        res.data.forEach(item => {
          if (typeNum.indexOf(item.spec_id) == -1) {
            this.examList.push(item)
            typeNum.push(item.spec_id)
          }
        })
      }
    })
  },
  methods: {
    // 轮播图广告跳转
    adInfo(list) {
      if (list.type == 1) {// 海报
       
      }
      if (list.type == 2) {// 课程列表
        let idArr = list.urlId.split(",")
        this.$router.push("/course/" + idArr[0] + "/" + idArr[1])
      }
    },
    // 获取专题下的套餐列表
    // specialIndex一级专题索引，specialChildIndex二级专题索引
    getComboList(specialIndex, specialChildIndex) {
      // 根据索引获取到二级专题对象
      const child = this.specialList[specialIndex].children[specialChildIndex]
      // 将选中的二级专题标记在一级专题中
      this.specialList[specialIndex].currentSpecialChild = child
      // 获取选中的二级专题下的套餐列表
      getCombo({spec_id: child.id}).then(res => {
        if (res.code == 1) {
          let comboList = []
          res.data.forEach(item => {
            if (item.status == 1 && item.combo && item.combo.length > 0) {
              item.thumb = item.combo[0].thumb
              item.price = item.combo[0].sale_price
              item.combo.forEach(combo => {
                if (combo.sale_price < item.price) {
                  item.price = combo.sale_price
                }
              })
              comboList.push(item)
            }
          })
          this.specialList[specialIndex].comboList = comboList.slice(-4)
        }
      })
    },
    // 跳转课程列表
    goCourse(special) {
      this.$router.push('/course/' + special.id + '/' + special.children[0].id)
    },
    // 跳转课程详情
    goCourseDetail(course){
      this.$router.push('/coursedetail/' + course.spec_id + '/' + course.id + '/' + course.combo[0].id)
    },
    // 跳转密押试卷详情
    goSecretDetail(paper) {
      setSessionStorage("currentTestGood", paper)
      this.$router.push('/secretdetail')
    },

  },
};
</script>

<style scoped lang="scss">
// 专题列表 begin
.special {
  background-color: $background-color;
  .special-item {
    height: 90px;
    border: 1px solid #eee;
    display: flex;
    .special-item-title {
      width: 90px;
      text-align: center;
      .title-container {
        padding: 10px;
        svg {
          color: $theme-color;
          font-size: 44px;
        }
        & > div {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .special-item-children {
      width: 290px;
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      .children-item {
        font-size: 12px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .children-item:hover {
        color: $theme-color;
      }
    }
  }
  .special-item:hover {
    border: 1px solid $theme-color;
  }
}
// 专题列表 end

// 轮播图 begin
.swiper-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
  .swiper-content-img {
    width: 100%;
    height: 460px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
// 轮播图 end

// 专题及套餐列表 begin
.combo {
  .combo-header {
    display: flex;
    align-items: center;
    .combo-header-title {
      font-size: 22px;
      border-left: 5px solid $theme-color;
      padding-left: 8px;
    }
    .combo-header-sub {
      width: 75%;
      line-height: 18px;
      font-size: 12px;
      margin-left: 30px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      .sub-item {
        padding: 0 10px;
        border-right: 1px solid #dedede;
        cursor: pointer;
      }
      .sub-item:last-child {
        border: none;
      }
      .sub-item:hover,
      .sub-item.active {
        color: $theme-color;
      }
    }
  }
  .combo-list {
    height: 470px;
    background-color: $background-color;
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .combo-list-item {
      width: 275px;
      margin: 10px;
      cursor: pointer;
      .item-img {
        height: 150px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-title {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 10px 0;
      }
      .item-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .bottom-price {
          color: $price-color;
          font-size: 16px;
          span {
            color: #999;
            font-size: 12px;
            margin-left: 3px;
          }
        }
        .bottom-num {
          color: #999;
          font-size: 12px;
        }
      }
    }
    .combo-list-item:hover {
      .item-title {
        color: $theme-color;
      }
    }
  }
}
.combo.last {
  .combo-list {
    height: auto;
  }
}
// 专题及套餐列表 end

// 题库 begin
.exam {
  margin: 30px 0;
  .exam-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .header-title {
      font-size: 22px;
      border-left: 5px solid $theme-color;
      padding-left: 8px;
    }
    .header-more {
      font-size: 12px;
      cursor: pointer;
    }
    .header-more:hover {
      color: $theme-color;
    }
  }
  .exam-list {
    background-color: $background-color;
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    .exam-list-item {
      width: 280px;
      margin: 10px;
      cursor: pointer;
      .item-img {
        height: 150px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-title {
        height: 42px;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 10px 0;
      }
      .item-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .bottom-price {
          color: $price-color;
          font-size: 16px;
          span {
            color: #999;
            font-size: 12px;
            margin-left: 3px;
          }
        }
        .bottom-num {
          color: #999;
          font-size: 12px;
        }
      }
    }
    .exam-list-item:hover {
      .item-title {
        color: $theme-color;
      }
    }
  }
}
// 题库 end
</style>